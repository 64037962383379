import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import styled, { css } from 'react-emotion'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { Pager } from '../utils/styles'
import Img from 'gatsby-image'
import Seo from '../components/Seo'
import { mq, colors, PageHeadings } from '../utils/styles'

const Post = styled('div')`
  display: flex;
  justify-content: center;
  ${mq({
    'flex-wrap': ['wrap', 'nowrap', 'nowrap', 'nowrap'],
    'padding-bottom': ['0rem', '0.5rem', '0.5rem', '0.5rem'],
  })};
`
const PostContent = styled('div')`
  ${mq({
    'padding-left': ['0rem', '1rem', '1rem', '1rem'],
  })};
`

const ImagePost = styled('div')`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`
const EpisodeText = styled('div')`
  display: block;
  padding-bottom: 1rem;
`

class MixTape extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          type="page"
          title="Mixtape"
          description={`The Finance for Hippies Podcast is proud to announce the very first mixtape by podcast hosts TeethSwag & Ndarkie titled Get Rich or Die a Hippie mixed by the incredibly talented C'funk`}
          path={`/mixtape/`}
        />
        <h1 style={{ display: 'none' }}>Mixtape</h1>
        <p>
          The Finance for Hippies Podcast is proud to announce the very first
          mixtape by podcast hosts TeethSwag & Ndarkie titled Get Rich or Die a
          Hippie mixed by the incredibly talented C'funk
        </p>
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/705401031&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        />

        <p>
          After years in the arts industry exhibiting as an interactive artist
          known as Mr MediaX, the artist finally gets the perfect platform to
          unleash and flex his rapper persona TeethSwag. He is joined on this
          tape by tag team rapper and music curator Ndarkie (aka Don Salamanca)
          - a man known to the South African government as a financial industry
          professional named Thabeng Sehume. As an extension of their podcast
          series, the mixtape takes a comedic and satirical look at the real
          life of two broke and unpopping artists through mirroring the life
          experiences of both TeethSwag and Ndarkie in their quest to reach
          financial freedom, correct their mistakes and break away from "the
          system"
        </p>
        <p>
          Sonically, it is curated as a tribute the early 90's hip hop scene and
          is a musical project that is designed to help you self reflect, take
          care a more grown up approach to your finances and most importantly -
          never forget to laugh at yourself in the process #ArtOverMoney
        </p>

        <div
          className={css`
            padding: 0.7rem;
          `}
        >
          <Img fluid={this.props.data.mix.childImageSharp.fluid} />
        </div>
      </Layout>
    )
  }
}

export default MixTape

export const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
        siteDescription
        siteUrl
      }
    }

    mix: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: "mix.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # cropFocus: CENTER
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

// allEpisodes(sort: {fields: [date], order: DESC}) {
//   edges{
//     node{
//       title
//       date(formatString: "MMMM D, YYYY")
//       description
//       fields{
//         slug
//         file{
//           publicURL
//         }
//       }
//     }
//   }
// }
